import React, { useContext } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import {
    GenverseTokenType,
    ManageMoralisUserTokensContextType,
} from '../../../../../types';
import InfiniteScroll from 'react-infinite-scroll-component';
import G3LogoSpinner from '../G3LogoSpinner';
import TokenItem from './components/TokenItem';
import { ManageMoralisUserTokensContext } from './contexts/ManageMoralisUserTokensContext';
import getTokenPK from "../../../utils/getTokenPK";

export default ({ containerProps }: { containerProps?: any }) => {
    const {
        selectedTokens,
        selectToken,
        publicTokens,
        // loadingPublicTokens,
        fetchNextPublicTokens,
        hasNextPublicTokens,
    } = useContext(
        ManageMoralisUserTokensContext
    ) as ManageMoralisUserTokensContextType;

    const PageLoader = () => (
        <Flex
            align="center"
            justify="center"
            backgroundColor="rgba(255, 255, 255, 0.01)"
            borderRadius="0.375rem"
            padding="1rem"
            marginTop="1rem"
        >
            <G3LogoSpinner
                containerProps={{
                    h: '5rem',
                    w: 'unset',
                }}
            />
            <Text marginLeft="1rem">Loading...</Text>
        </Flex>
    );

    return (
        <Box {...containerProps}>
            <InfiniteScroll
                scrollableTarget="scrollContainer"
                scrollThreshold={0.5}
                dataLength={publicTokens?.length || 0}
                next={fetchNextPublicTokens}
                hasMore={hasNextPublicTokens}
                style={{
                    padding: '0 0 2rem 0',
                }}
                loader={<PageLoader />}
            >
                <Flex direction="column">
                    {publicTokens?.map((token: GenverseTokenType) => (
                        <TokenItem
                            isSelected={
                                !!selectedTokens?.find(
                                    (selectedToken) =>
                                        getTokenPK(selectedToken) ==
                                        getTokenPK(token)
                                )
                            }
                            token={token}
                            onClick={() => selectToken(token)}
                        />
                    ))}
                </Flex>
            </InfiniteScroll>
        </Box>
    );
};
