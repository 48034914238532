import {
    GenverseTokenMetadataType,
    GenverseTokenType,
} from '../../../../../../types';
import {
    AspectRatio,
    Box,
    Flex,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import NFTImage from '../../NFTImage';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

export default ({
    isSelected,
    token,
    onClick,
    showUnselectIcon,
}: {
    isSelected?: boolean;
    token: GenverseTokenType;
    onClick: () => void;
    showUnselectIcon?: boolean;
}) => (
    <Flex
        align="center"
        padding=".5rem"
        borderRadius="0.375rem"
        // backgroundColor={
        //     getTokenPK(selectedToken) === getTokenPK(token)
        //         ? 'primary'
        //         : 'transparent'
        // }
        cursor="pointer"
        onClick={onClick}
    >
        {showUnselectIcon && (
            <Box marginRight="1rem">
                <AiOutlineClose />
            </Box>
        )}
        <AspectRatio w={['2.5rem', '3rem']} ratio={1}>
            <NFTImage
                border={
                    isSelected
                        ? `solid 2px ${useColorModeValue('black', 'white')}`
                        : useColorModeValue(
                              'solid 1px rgba(0, 0, 0, 0.2)',
                              'solid 1px rgba(255, 255, 255, 0.1)'
                          )
                }
                borderRadius={`50%`}
                token={
                    {
                        metadata: token?.metadata as GenverseTokenMetadataType,
                    } as GenverseTokenType
                }
                alt={token?.metadata?.name}
                // onClick={() => {
                //     if (
                //         !!pageToken?.chain &&
                //         !!pageToken?.tokenAddress &&
                //         !!pageToken?.tokenId
                //     ) {
                //         navigate(
                //             `/token/${pageToken.chain}/${pageToken.tokenAddress}/${pageToken.tokenId}`
                //         );
                //     }
                // }}
            />
        </AspectRatio>
        <Box flex={1} marginLeft="1rem">
            <Text fontSize=".8rem" fontWeight="bold">
                {token?.contract?.name}
            </Text>
            <Text>{token?.metadata?.name}</Text>
        </Box>
    </Flex>
);
