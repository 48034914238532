import React, { useContext, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import {
    GenverseTokenType,
    ManageMoralisUserTokensContextType,
    SearchWalletResultsDataType,
} from '../../../../../types';
import G3LogoSpinner from '../G3LogoSpinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import TokenItem from './components/TokenItem';
import { ManageMoralisUserTokensContext } from './contexts/ManageMoralisUserTokensContext';
import getTokenPK from '../../../utils/getTokenPK';
import SearchWalletTokensInput from '../../base/SearchWalletTokensInput';
import { useMoralis } from 'react-moralis';

const PageLoader = () => (
    <Flex
        align="center"
        justify="center"
        backgroundColor="rgba(255, 255, 255, 0.01)"
        borderRadius="0.375rem"
        padding="1rem"
        marginTop="1rem"
    >
        <G3LogoSpinner
            containerProps={{
                h: '5rem',
                w: 'unset',
            }}
        />
        <Text marginLeft="1rem">Loading...</Text>
    </Flex>
);

const TokensScrollList = ({
    data,
    fetchMore,
    hasMore,
}: {
    data: GenverseTokenType[];
    fetchMore: () => void;
    hasMore: boolean;
}) => {
    const { selectedTokens, selectToken } = useContext(
        ManageMoralisUserTokensContext
    ) as ManageMoralisUserTokensContextType;

    return (
        <InfiniteScroll
            scrollableTarget="scrollContainer"
            scrollThreshold={0.5}
            dataLength={data?.length || 0}
            next={fetchMore}
            hasMore={hasMore}
            style={{
                padding: '0 0 2rem 0',
            }}
            loader={<PageLoader />}
        >
            <Flex direction="column">
                {data?.map((token: GenverseTokenType) => (
                    <TokenItem
                        isSelected={
                            !!selectedTokens?.find(
                                (selectedToken) =>
                                    getTokenPK(selectedToken) ==
                                    getTokenPK(token)
                            )
                        }
                        token={token}
                        onClick={() => selectToken(token)}
                    />
                ))}
            </Flex>
        </InfiniteScroll>
    );
};

export default ({
    containerProps,
    searchContainerProps,
}: {
    containerProps?: any;
    searchContainerProps?: any;
}) => {
    const { user } = useMoralis();
    const currentUserAddress = user?.get('ethAddress');

    const {
        allTokens,
        // loadingAllTokens,
        fetchNextAllTokens,
        hasNextAllTokens,
    } = useContext(
        ManageMoralisUserTokensContext
    ) as ManageMoralisUserTokensContextType;

    const [searchResults, setSearchResults] = useState(
        {} as SearchWalletResultsDataType
    );

    const onSearchResults = (
        searchResultsData: SearchWalletResultsDataType
    ) => {
        setSearchResults(searchResultsData);
    };

    // const isLoading = loadingAllTokens || searchResults?.loading;

    return (
        <Box>
            <SearchWalletTokensInput
                key={currentUserAddress}
                useURLQueryParams
                walletAddress={currentUserAddress}
                onSearchResults={onSearchResults}
                containerProps={searchContainerProps}
            />
            <Box {...containerProps}>
                {searchResults?.searchResults ? (
                    searchResults?.searchResults?.length > 0 ? (
                        <TokensScrollList
                            data={searchResults?.searchResults}
                            hasMore={searchResults?.hasNextPage}
                            fetchMore={searchResults?.loadNextPage}
                        />
                    ) : (
                        <Text
                            padding="5rem 1rem"
                            textAlign="center"
                        >{`No results found for "${searchResults?.searchQueryText}"`}</Text>
                    )
                ) : (
                    <TokensScrollList
                        data={allTokens}
                        hasMore={hasNextAllTokens}
                        fetchMore={fetchNextAllTokens}
                    />
                )}
            </Box>
        </Box>
    );
};
