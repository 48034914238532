import React, { useContext, useState } from 'react';
import {
    Box,
    Flex,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import G3Tab from '../../../general/G3Tab';
import PageHeader from '../../components/PageHeader';
import ManageMoralisUserTokensProvider, {
    ManageMoralisUserTokensContext,
} from '../../../general/G3TokensScrollList/contexts/ManageMoralisUserTokensContext';
import {
    GenverseTokenType,
    ManageMoralisUserTokensContextType,
} from '../../../../../../types';
import G3PublicTokensScrollList from '../../../general/G3TokensScrollList/PublicTokensList';
import G3AllTokensScrollList from '../../../general/G3TokensScrollList/AllTokensList';
import G3HiddenTokensScrollList from '../../../general/G3TokensScrollList/HiddenTokensList';
import G3Button from '../../../general/G3Button';
import TokenItem from '../../../general/G3TokensScrollList/components/TokenItem';
import useGenversePage from '../../../../hooks/useGenversePage';
import { useMoralis } from 'react-moralis';
import { Link } from 'gatsby';

const Page = () => {
    const {
        selectToken,
        clearSelectedTokens,
        selectedTokens,
        updateSelectedTokensSettings,
        updatingSelectedTokensSettings,
    } = useContext(
        ManageMoralisUserTokensContext
    ) as ManageMoralisUserTokensContextType;

    const { user } = useMoralis();

    const ethAddress = user?.get('ethAddress');

    const [tabIndex, setTabIndex] = useState(0);

    const changeTabIndex = (tabIndex: number) => {
        setTabIndex(tabIndex);
        clearSelectedTokens();
    };

    const { genversePage, loading } = useGenversePage({ address: ethAddress });

    const subtitle = loading
        ? ''
        : (genversePage?.page?.tokensDisplaySetting || 'DISPLAY_ALL') ===
          'DISPLAY_ALL'
        ? 'Currently all your NFTs are publicly visible (except those you have marked as  hidden)'
        : 'Only NFTs marked as public are publicly visible.';
    return (
        <Box>
            <PageHeader title="Collected NFTs" />
            {subtitle?.length > 0 && (
                <Box marginBottom="1rem">
                    <Text>{subtitle}</Text>
                    <Link
                        to="/dashboard/metapage/customize"
                        style={{
                            color: '#AB2FB7',
                        }}
                    >
                        Customise Tokens Display Setting
                    </Link>
                </Box>
            )}
            <Flex gap="1rem" position="relative">
                <Tabs
                    flex={0.7}
                    variant="soft-rounded"
                    colorScheme="whiteAlpha"
                    // align="center"
                    marginTop="1rem"
                    index={tabIndex}
                    defaultIndex={tabIndex}
                    onChange={(index) => changeTabIndex(index)}
                >
                    <TabList>
                        <G3Tab margin="0 0.5rem 0 0">All NFTs</G3Tab>
                        <G3Tab subtitle="What others see">Public</G3Tab>
                        <G3Tab subtitle="No one can see">Hidden</G3Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel padding={'1rem 0 0 0'}>
                            <G3AllTokensScrollList
                                searchContainerProps={{
                                    width: ['100%', '50%'],
                                }}
                            />
                        </TabPanel>
                        <TabPanel padding={'1rem 0 0 0'}>
                            <G3PublicTokensScrollList />
                        </TabPanel>
                        <TabPanel padding={'1rem 0 0 0'}>
                            <G3HiddenTokensScrollList />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <Box flex={0.3}>
                    <Flex
                        align="center"
                        justify="flex-end"
                        gap=".5rem"
                        marginBottom="1rem"
                    >
                        {(tabIndex === 0 || tabIndex === 2) && (
                            <G3Button
                                disabled={!selectedTokens?.length}
                                size="sm"
                                isLoading={
                                    updatingSelectedTokensSettings === 'SHOW'
                                }
                                onClick={() =>
                                    updateSelectedTokensSettings('SHOW')
                                }
                            >
                                Mark as Public
                            </G3Button>
                        )}
                        {(tabIndex === 0 || tabIndex === 1) && (
                            <G3Button
                                disabled={!selectedTokens?.length}
                                size="sm"
                                isLoading={
                                    updatingSelectedTokensSettings === 'HIDE'
                                }
                                onClick={() =>
                                    updateSelectedTokensSettings('HIDE')
                                }
                            >
                                Mark as Hidden
                            </G3Button>
                        )}
                    </Flex>
                    <Box
                        position="sticky"
                        top={'1rem'}
                        borderRadius={'0.375rem'}
                        border={useColorModeValue(
                            'solid 1px rgba(0, 0, 0, 0.2)',
                            'solid 1px rgba(255, 255, 255, 0.1)'
                        )}
                    >
                        <Flex align="flex-start" justify="space-between">
                            <Text margin="0.5rem .5rem 0 .5rem">
                                Selected NFTs
                            </Text>
                        </Flex>
                        {!selectedTokens?.length && (
                            <Box padding="1rem">
                                <Text fontSize="0.8rem" textAlign="center">
                                    Click on an NFT to select it to perform an
                                    action.
                                </Text>
                            </Box>
                        )}
                        {selectedTokens?.map((token: GenverseTokenType) => (
                            <TokenItem
                                token={token}
                                showUnselectIcon
                                onClick={() =>
                                    selectToken(token, { remove: true })
                                }
                            />
                        ))}
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

export default () => (
    <ManageMoralisUserTokensProvider>
        <Page />
    </ManageMoralisUserTokensProvider>
);
