import { useMoralis } from 'react-moralis';
import { useEffect, useState } from 'react';
import { flatten } from 'lodash';

export default (chains: string[]) => {
    const { Moralis, isAuthenticated, user } = useMoralis();

    if (!isAuthenticated || !user?.get('ethAddress'))
        return { isLoading: false };

    const address = user.get('ethAddress');
    const [isLoading, setIsLoading] = useState(false);

    const [balances, setBalances] = useState(
        {} as {
            native: {
                chain: string;
                balance: string;
            }[];
            erc20: any[];
        }
    );

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const nativeBalances = await Promise.all(
                chains.map(async (chain) => ({
                    chain,
                    ...(await Moralis.Web3API.account.getNativeBalance({
                        // @ts-ignore
                        chain,
                        address,
                    })),
                }))
            );

            const erc20Balances = flatten(
                await Promise.all(
                    chains.map(async (chain) =>
                        (
                            await Moralis.Web3API.account.getTokenBalances({
                                // @ts-ignore
                                chain: chain?.replace('matic', 'polygon') as
                                    | 'eth'
                                    | 'polygon',
                                address,
                            })
                        )?.map((data) => ({ ...data, chain }))
                    )
                )
            );

            setBalances({
                native: nativeBalances,
                erc20: erc20Balances,
            });
            setIsLoading(false);
        })();
    }, []);

    return {
        balances,
        isLoading,
    };
};
