import SearchPageQuery from '../../api/searchGenversePage.query.gql';
import { useApolloClient } from '@apollo/client';
import resolveENS from '../utils/resolveENS';
import { useState } from 'react';

// Refetches an address from page and thus updates apollo cache entries
export default () => {
    const apolloClient = useApolloClient();
    const [refetching, setRefetching] = useState(false);

    const refetchServerPage = async (walletAddress: string) => {
        setRefetching(true);
        const { ensName, address } = await resolveENS(walletAddress);

        await apolloClient.query({
            query: SearchPageQuery,
            variables: {
                address,
            },
            fetchPolicy: 'network-only',
        });

        if (ensName) {
            await apolloClient.query({
                query: SearchPageQuery,
                variables: {
                    address: ensName,
                },
                fetchPolicy: 'network-only',
            });
        }

        setRefetching(false);
    };
    return { refetchServerPage, refetching };
};
