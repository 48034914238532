import React from 'react';
import useNativeBalances from '../../../../../../hooks/useNativeBalances';
import Tile from '../../../../../base/Tile';
import { useMoralis } from 'react-moralis';
import { Flex, Text } from '@chakra-ui/react';
import formatChain from '../../../../../../utils/formatChain';
import ChainImage from '../../../../../general/ChainImage';
import G3LogoSpinner from '../../../../../general/G3LogoSpinner';

export default ({ containerProps }: { containerProps?: any }) => {
    const { Moralis } = useMoralis();
    const { balances, isLoading } = useNativeBalances(['eth', 'matic']);

    const nativeBalancesFormatted = balances?.native?.map(
        ({ chain, balance }: { chain: string; balance: string }) => {
            const balanceAbsolute = Moralis.Units.FromWei(balance, 18);

            return {
                balance: balanceAbsolute,
                balanceRounded: formatChain(
                    // @ts-ignore
                    parseFloat(balanceAbsolute)
                        ?.toLocaleString(undefined, {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4,
                        })
                        ?.replace('.0000', ''),
                    chain
                ),
                chain,
            };
        }
    );

    return (
        <Flex direction="column">
            <Text fontSize="1rem" opacity="0.5" marginBottom="1rem">
                Balances
            </Text>
            <Flex direction={['column', 'row']} gap="1rem" {...containerProps}>
                {isLoading && (
                    <G3LogoSpinner
                        containerProps={{
                            h: '20vh',
                        }}
                    />
                )}
                {!isLoading && (
                    <>
                        {nativeBalancesFormatted?.map(
                            ({
                                balanceRounded,
                                chain,
                            }: {
                                balanceRounded: string;
                                chain: string;
                            }) => (
                                <Tile
                                    flex={1 / 3}
                                    justify="center"
                                    align="center"
                                    padding={['2rem 0', 0]}
                                    minHeight="10rem"
                                >
                                    <Flex
                                        direction="column"
                                        align="center"
                                        justify="center"
                                    >
                                        <Text
                                            textAlign="center"
                                            fontWeight="bold"
                                            fontSize="1.3rem"
                                        >
                                            {balanceRounded}
                                        </Text>
                                        <Flex align="center">
                                            <ChainImage
                                                containerProps={{
                                                    marginRight: 0,
                                                    height: '1.5rem',
                                                    width: '1.5rem',
                                                }}
                                                chain={
                                                    chain?.replace(
                                                        'matic',
                                                        'polygon'
                                                    ) as 'eth' | 'polygon'
                                                }
                                            />
                                            <Text fontSize="0.8rem">
                                                {`${
                                                    {
                                                        eth: 'Ethereum',
                                                        polygon: 'Polygon',
                                                        matic: 'Polygon',
                                                    }[chain]
                                                }`}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Tile>
                            )
                        )}
                        <Tile
                            flex={1 / 3}
                            justify="center"
                            align="center"
                            padding={['2rem 0', 0]}
                            minHeight="10rem"
                        />
                    </>
                )}
            </Flex>
        </Flex>
    );
};
