import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from '@chakra-ui/react';
import Tile from '../../../../../base/Tile';
import moment from 'moment';
import VisitsChart from './components/VisitsChart';
import G3LogoSpinner from '../../../../../general/G3LogoSpinner';

export default ({ containerProps }: { containerProps?: any }) => {
    const { Moralis, isAuthenticated } = useMoralis();

    const [metaPageWebInsights, setMetaPageWebInsights] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                setIsLoading(true);
                const response = await Moralis.Cloud.run(
                    'getMetaPageVisitsInsights',
                    {
                        stage: process.env.GATSBY_STAGE,
                        overview: true,
                        // interval: 'day',
                    }
                );

                setIsLoading(false);
                setMetaPageWebInsights(response?.data);
            })();
        }
    }, [isAuthenticated]);

    return (
        <Flex direction="column" width={'100%'}>
            <Text fontSize="1rem" opacity="0.5" marginBottom="1rem">
                MetaPage Visits
            </Text>
            <Tile
                direction={['column', 'row']}
                gap="1rem"
                width="100%"
                {...containerProps}
            >
                <Flex flex={[1, 0.5]}>
                    <VisitsChart />
                </Flex>
                <Flex flex={[1, 0.5]}>
                    {!isLoading ? (
                        <Table
                            size="lg"
                            variant='unstyled'
                            marginTop="1rem"
                        >
                            <Thead>
                                <Tr>
                                    {[
                                        '',
                                        'Total Visits',
                                        'Visitors',
                                        'Avg Visit Duration',
                                    ]?.map((label) => (
                                        <Th
                                            key={`th-${label}`}
                                            color={useColorModeValue(
                                                'rgba(0, 0, 0, 0.5)',
                                                'rgba(255, 255, 255, 0.5)'
                                            )}
                                            fontSize=".8rem"
                                            px=".5rem"
                                            py=".5rem"
                                            textAlign="right"
                                        >
                                            {label}
                                        </Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {metaPageWebInsights?.map(
                                    (
                                        {
                                            label,
                                            data,
                                        }: {
                                            label: string;
                                            data: {
                                                totalVisits: number;
                                                uniqueVisits: number;
                                                avgVisitDuration: number;
                                            };
                                        },
                                        keyIndex
                                    ) => (
                                        <Tr
                                            background={
                                                keyIndex % 2 === 0
                                                    ? useColorModeValue(
                                                          'rgba(0, 0, 0, 0.1)',
                                                          'rgba(0, 0, 0, 0.3)'
                                                      )
                                                    : 'transparent'
                                            }
                                        >
                                            {[
                                                label,
                                                data?.totalVisits,
                                                data?.uniqueVisits,
                                                data?.avgVisitDuration
                                                    ? moment
                                                          .utc(
                                                              data?.avgVisitDuration *
                                                                  1000
                                                          )
                                                          .format('mm[m] ss[s]')
                                                    : '',
                                            ]?.map((value, index) => (
                                                <Td
                                                    key={`td-${label}`}
                                                    color={useColorModeValue(
                                                        'black',
                                                        'white'
                                                    )}
                                                    fontSize="1rem"
                                                    px=".5rem"
                                                    py=".5rem"
                                                    textAlign="right"
                                                >
                                                    {value}
                                                </Td>
                                            ))}
                                        </Tr>
                                    )
                                )}
                            </Tbody>
                        </Table>
                    ) : (
                        <G3LogoSpinner
                            containerProps={{
                                h: '20vh',
                            }}
                        />
                    )}
                </Flex>
            </Tile>
        </Flex>
    );
};
