import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import useGenversePage from '../../../../../../hooks/useGenversePage';
import Tile from '../../../../../base/Tile';
// @ts-ignore
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IoIosAdd } from 'react-icons/io';
import { GenversePageLink } from '../../../../../../../../types';
import { v1 as uuidv1 } from 'uuid';
import { isEqual } from 'lodash';
import toast from 'react-hot-toast';
import useRefetchPageFromServer from '../../../../../../hooks/useRefetchPageFromServer';
import { useMoralis } from 'react-moralis';
import { PAGE_PLATFORM_LINKS } from '../../../../../../consts/METAPAGE_FIELDS';
import G3Button from '../../../../../general/G3Button';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import LinkItem from './component/LinkItem';

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default ({ address }: { address: string }) => {
    const { Moralis } = useMoralis();
    const { genversePage, loading } = useGenversePage({ address });
    const { refetchServerPage } = useRefetchPageFromServer();
    const [items, setItems] = useState(
        (genversePage?.page?.platformLinks || []) as GenversePageLink[]
    );

    // @ts-ignore
    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        borderRadius: '0.375rem',
        // change background colour if dragging
        backdropFilter: useColorModeValue(
            'brightness(100%)',
            'brightness(200%)'
        ),
        background: useColorModeValue(
            'transparent',
            'rgba(218, 175, 255, 0.05)'
        ),

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
    });

    const onClickAddLink = () => {
        setItems([
            { uuid: uuidv1() } as GenversePageLink,
            ...(items ? items : []),
        ]);
    };
    // @ts-ignore
    const onCreateChanges = (updatedLink: GenversePageLink) => {
        setItems(
            items?.map((item) =>
                item?.uuid === updatedLink?.uuid ? updatedLink : item
            )
        );
    };

    // @ts-ignore
    const onClickDeleteLink = (deleteLink: GenversePageLink) => {
        setItems(items?.filter((item) => item?.uuid !== deleteLink?.uuid));
    };

    useEffect(() => {
        if (!loading) {
            setItems(genversePage?.page?.platformLinks);
        }
    }, [genversePage, loading]);

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(newItems as any);
    };
    const hasUpdates = !isEqual(items, genversePage?.page?.platformLinks);

    const [updating, setUpdating] = useState(false);

    const onSaveUpdates = async () => {
        setUpdating(true);
        const updatedPage = await Moralis.Cloud.run('updatePage', {
            stage: process.env.GATSBY_STAGE,
            page: {
                [PAGE_PLATFORM_LINKS]: items,
            },
        });
        setUpdating(false);
        toast.success('Updated successfully!', {
            style: {
                background: '#333',
                color: '#fff',
            },
        });
        await refetchServerPage(updatedPage.data.address);
    };

    return (
        <Tile>
            <Text textAlign="center" opacity="0.7">
                Links
            </Text>
            <Text
                textAlign="center"
                marginBottom="1rem"
                opacity="0.7"
                fontSize="0.8rem"
            >
                Drag and drop to rearrange the order of your MetaPage links.
            </Text>
            <Flex align="center" justify="center" marginBottom="2rem">
                {hasUpdates && (
                    <G3Button
                        size="sm"
                        variant="outline"
                        isLoading={updating}
                        leftIcon={
                            <CgArrowsExchangeAlt
                                size="2rem"
                                opacity="0.5"
                                color={useColorModeValue('black', 'white')}
                            />
                        }
                        onClick={onSaveUpdates}
                        marginRight="1rem"
                    >
                        Save Changes
                    </G3Button>
                )}
                <G3Button
                    size="sm"
                    variant="outline"
                    leftIcon={
                        <IoIosAdd
                            size="2rem"
                            opacity="0.5"
                            color={useColorModeValue('black', 'white')}
                        />
                    }
                    onClick={onClickAddLink}
                >
                    Add Link
                </G3Button>
            </Flex>
            <Box>
                {items?.length > 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided: any, snapshot: any) => (
                                <Box
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(
                                        snapshot.isDraggingOver
                                    )}
                                >
                                    {items?.map((item: any, index: number) => (
                                        <Draggable
                                            key={item.uuid}
                                            draggableId={item.uuid}
                                            index={index}
                                        >
                                            {(provided: any, snapshot: any) => {
                                                if (snapshot.isDragging) {
                                                    provided.draggableProps.style.left =
                                                        undefined;
                                                    provided.draggableProps.style.top =
                                                        undefined;
                                                }
                                                return (
                                                    <LinkItem
                                                        link={item}
                                                        containerProps={{
                                                            ref: provided.innerRef,
                                                            ...provided.draggableProps,
                                                            style: getItemStyle(
                                                                snapshot.isDragging,
                                                                provided
                                                                    .draggableProps
                                                                    .style
                                                            ),
                                                        }}
                                                        gripProps={{
                                                            ...provided.dragHandleProps,
                                                        }}
                                                        onCreateChanges={
                                                            onCreateChanges
                                                        }
                                                        onClickDelete={
                                                            onClickDeleteLink
                                                        }
                                                    />
                                                );
                                            }}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </Box>
        </Tile>
    );
};
