import { GenversePageLink } from '../../../../../../../../../types';
import React, { useEffect, useState } from 'react';
import { Box, Flex, Input, useColorModeValue } from '@chakra-ui/react';
import { BsGripVertical, BsTrash } from 'react-icons/bs';
import { SocialIcon } from 'react-social-icons';

let timeout: any;

export default ({
    link,
    containerProps,
    gripProps,
    onCreateChanges,
    onClickDelete,
}: {
    link: GenversePageLink;
    containerProps?: any;
    gripProps?: any;
    onCreateChanges?: (updatedLink: GenversePageLink) => void;
    onClickDelete?: (deleteLink: GenversePageLink) => void;
}) => {
    const [label, setLabel] = useState(link.label);
    const [url, setUrl] = useState(link.url);

    const canSave = label?.length > 0 && url?.length > 0;

    useEffect(() => {
        if (!canSave || !onCreateChanges) return;

        const hasChanges = label !== link.label || url !== link.url;
        if (!hasChanges) return;

        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
            (async () => {
                await onCreateChanges({ ...link, label, url });
            })();
        }, 250);

        return () => {
            clearTimeout(timeout);
        };
    }, [label, url, link]);
    return (
        <Flex
            align="center"
            borderRadius="0.375rem"
            padding="2rem 1rem"
            marginBottom="1rem"
            {...containerProps}
        >
            <div {...gripProps}>
                <BsGripVertical size="1.5rem" opacity="0.5" />
            </div>
            <Box flex={1} marginLeft="1rem">
                <Flex
                    align="center"
                    marginBottom="1rem"
                    justify="space-between"
                >
                    <Flex align="center" flex={1} marginRight="1rem">
                        <SocialIcon
                            bgColor={useColorModeValue(
                                'rgba(0, 0, 0, 0.8)',
                                'rgba(255, 255, 255, 0.8)'
                            )}
                            fgColor={useColorModeValue('white', 'black')}
                            url={url}
                            style={{
                                width: '2.5rem',
                                height: '2.5rem',
                            }}
                            target="_blank"
                        />
                        <Input
                            marginLeft="1rem"
                            placeholder="Link name e.g. Twitter, Discord, OpenSea, etc."
                            borderColor={useColorModeValue(
                                'rgba(0, 0, 0, 0.2)',
                                'rgba(255, 255, 255, 0.1)'
                            )}
                            // flex={1}
                            width={['100%', '50%']}
                            value={label}
                            fontSize=".8rem"
                            onChange={(e) => setLabel(e.target.value)}
                        />
                    </Flex>
                    <Flex align="center">
                        <BsTrash
                            size="1rem"
                            opacity="0.5"
                            cursor="pointer"
                            onClick={() => {
                                if (onClickDelete) {
                                    onClickDelete({
                                        ...link,
                                        label,
                                        url,
                                    });
                                }
                            }}
                        />
                    </Flex>
                </Flex>
                <Input
                    placeholder="Link url e.g. https://twitter.com/genv3rse"
                    borderColor={useColorModeValue(
                        'rgba(0, 0, 0, 0.2)',
                        'rgba(255, 255, 255, 0.1)'
                    )}
                    value={url}
                    fontSize=".8rem"
                    onChange={(e) => setUrl(e.target.value)}
                />
            </Box>
        </Flex>
    );
};
