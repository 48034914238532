import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader';
import { useMoralis } from 'react-moralis';
import useENSName from '../../../../hooks/useENSName';
import useGenversePage from '../../../../hooks/useGenversePage';
import NativeBalancesTile from './components/NativeBalancesTile';
import ERC20BalancesTile from './components/ERC20BalancesTile';
import TransactionsTile from './components/TransactionsTile';
import MetaPageInsightsVisitsTile from './components/MetaPageInsightsVisitsTile';
import G3LogoSpinner from '../../../general/G3LogoSpinner';
import MetaPageInsightsLinksTile from './components/MetaPageInsightsLinksTile';
import MetaPageInsightsLiveVisitsTile from './components/MetaPageInsightsLiveVisitsTile';
import { navigate } from 'gatsby';

export default () => {
    const { user } = useMoralis();

    const ethAddress = user?.get('ethAddress');
    const { ensName, address } = useENSName(ethAddress);

    const { loading } = useGenversePage({ address: ethAddress });

    return (
        <Box>
            <PageHeader
                title="Dashboard"
                subtitle={ensName || address}
                subtitleAction={() => {
                    navigate(`/page/${ensName || address}`);
                }}
            >
                <MetaPageInsightsLiveVisitsTile />
            </PageHeader>
            <Flex
                marginTop="1rem"
                gap="1rem"
                direction={['column', 'row']}
                padding={['0 1rem', 0]}
            >
                {loading && (
                    <Flex align="center" justify="center" h="20vh" w="100%">
                        <G3LogoSpinner
                            containerProps={{
                                h: '20vh',
                            }}
                        />
                    </Flex>
                )}
                {!loading && (
                    <Flex direction={'column'} gap="1rem" width="100%">
                        <MetaPageInsightsVisitsTile />
                        <Flex direction={['column', 'row']} gap="1rem">
                            <Flex flex={0.5} direction="column" gap="1rem">
                                <NativeBalancesTile />
                                <TransactionsTile />
                                {/*<LinksTile address={address} />*/}
                            </Flex>
                            <Flex flex={0.5} direction="column" gap="1rem">
                                <MetaPageInsightsLinksTile />
                                <ERC20BalancesTile />
                            </Flex>
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Box>
    );
};
