import React, { useEffect, useState } from 'react';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import useGenversePage from '../../../../../../hooks/useGenversePage';
import Tile from '../../../../../base/Tile';
import { useMoralis } from 'react-moralis';
import useRefetchPageFromServer from '../../../../../../hooks/useRefetchPageFromServer';
import toast from 'react-hot-toast';
import { PAGE_TOKENS_DISPLAY_SETTING } from '../../../../../../consts/METAPAGE_FIELDS';
import G3DropdownMenu from '../../../../../general/G3DropdownMenu';
import G3LogoSpinner from '../../../../../general/G3LogoSpinner';

export default ({ address }: { address: string }) => {
    const { Moralis } = useMoralis();
    const { genversePage, loading } = useGenversePage({ address });
    const { refetchServerPage, refetching } = useRefetchPageFromServer();
    const [tokenDisplaySetting, setTokenDisplaySetting] = useState(
        genversePage?.page?.tokensDisplaySetting
    );

    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        if (!loading) {
            setTokenDisplaySetting(
                genversePage?.page?.tokensDisplaySetting || 'DISPLAY_ALL'
            );
        }
    }, [genversePage, loading]);

    const onUpdateTokenDisplaySetting = async (newTokenDisplaySetting: any) => {
        setUpdating(true);
        const updatedPage = await Moralis.Cloud.run('updatePage', {
            stage: process.env.GATSBY_STAGE,
            page: {
                [PAGE_TOKENS_DISPLAY_SETTING]: newTokenDisplaySetting,
            },
        });
        setUpdating(false);
        toast.success('Updated successfully!', {
            style: {
                background: '#333',
                color: '#fff',
            },
        });
        await refetchServerPage(updatedPage.data.address);
    };

    const menuItems = [
        {
            show: true,
            label: 'Display All',
            value: 'DISPLAY_ALL',
            onClick: () => onUpdateTokenDisplaySetting('DISPLAY_ALL'),
        },
        {
            show: true,
            label: 'Only Display Selected',
            value: 'DISPLAY_SELECTED',
            onClick: () => onUpdateTokenDisplaySetting('DISPLAY_SELECTED'),
        },
    ];

    const getCurrentLabel = () =>
        menuItems?.find(({ value }) => value === tokenDisplaySetting)?.label;

    const filteredMenuItems = menuItems
        ?.filter(({ value }) => value !== tokenDisplaySetting)
        .filter(({ show }) => !!show);

    return (
        <Tile>
            <Text textAlign="center" opacity="0.7">
                Tokens Display Setting
            </Text>
            <Text
                textAlign="center"
                marginBottom="1rem"
                opacity="0.7"
                fontSize="0.8rem"
            >
                Configure the behaviour of tokens displayed on your MetaPage.
            </Text>
            <Flex direction="column" align="center">
                <G3DropdownMenu
                    menuItems={filteredMenuItems}
                    menuButtonProps={{
                        border: useColorModeValue(
                            'solid 1px rgba(0, 0, 0, .2)',
                            'solid 1px rgba(255, 255, 255, .2)'
                        ),
                    }}
                >
                    <Flex align="center">
                        {(updating || loading || refetching) && (
                            <G3LogoSpinner
                                containerProps={{
                                    w: '2rem',
                                }}
                            />
                        )}
                        <Text fontSize="1rem" marginLeft="1rem">
                            {getCurrentLabel()}
                        </Text>
                    </Flex>
                </G3DropdownMenu>
            </Flex>
        </Tile>
    );
};
