import { useMoralis } from 'react-moralis';
import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';

export default (chains: string[]) => {
    const { Moralis, isAuthenticated, user } = useMoralis();

    if (!isAuthenticated || !user?.get('ethAddress'))
        return { transactions: [], isLoading: false };

    const address = user.get('ethAddress');
    const [isLoading, setIsLoading] = useState(false);

    // @ts-ignore
    const [transactions, setTransactions] = useState([] as any[]);

    // @ts-ignore
    const [cursors, setCursors] = useState({ eth: null, matic: null } as {
        eth: string | null;
        matic: string | null;
    });

    const fetchMoreTransactions = async () => {
        if (isLoading) return;
        setIsLoading(true);
        const response = await Promise.all(
            chains.map(async (chain) => ({
                chain,
                ...(await Moralis.Web3API.account.getTransactions({
                    // @ts-ignore
                    chain,
                    address,
                    cursor: cursors?.[chain as 'eth' | 'matic'],
                })),
            }))
        );

        const newResults = response?.reduce(
            // @ts-ignore
            (
                acc: any[],
                { result, chain }: { result: any[]; chain: string }
            ) => {
                return [...acc, ...result?.map((data) => ({ ...data, chain }))];
            },
            []
        );

        const updatedTransactions = orderBy(
            // @ts-ignore
            [...transactions, ...newResults],
            ['block_timestamp'],
            ['desc']
        );

        setTransactions(updatedTransactions);
        setCursors({
            // @ts-ignore
            eth: response?.find(({ chain }) => chain === 'eth')?.cursor || null,
            matic:
                // @ts-ignore
                response?.find(({ chain }) => chain === 'matic')?.cursor ||
                null,
        });
        // setCursor()
        // // @ts-ignore
        // if (newResults?.length === 0) {
        //     setCursor(null);
        // } else {
        //     setCursor();
        // }

        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await fetchMoreTransactions();
        })();
    }, []);

    const hasMore = !!cursors?.eth || !!cursors?.matic;
    return {
        transactions,
        hasMore,
        fetchMoreTransactions,
        isLoading,
    };
};
