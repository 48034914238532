import React, { useEffect, useState } from 'react';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import useGenversePage from '../../../../../../hooks/useGenversePage';
import Tile from '../../../../../base/Tile';
import { useMoralis } from 'react-moralis';
import useRefetchPageFromServer from '../../../../../../hooks/useRefetchPageFromServer';
import toast from 'react-hot-toast';
import { PAGE_THEME } from '../../../../../../consts/METAPAGE_FIELDS';
import { GLOW_BACKGROUNDS } from '../../../../../../consts/THEME_OPTIONS';
import { isEqual, keys } from 'lodash';
import { BackgroundColoursTypes } from '../../../../../../../../types';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import G3Button from '../../../../../general/G3Button';

export default ({ address }: { address: string }) => {
    const { Moralis } = useMoralis();
    const { genversePage, loading } = useGenversePage({ address });
    const { refetchServerPage } = useRefetchPageFromServer();

    const [theme, setTheme] = useState(genversePage?.page?.theme);
    // @ts-ignore
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        if (!loading) {
            setTheme(genversePage?.page?.theme);
        }
    }, [genversePage, loading]);

    const BACKGROUNDS = keys(GLOW_BACKGROUNDS) as BackgroundColoursTypes[];

    const hasUpdates = !isEqual(theme, genversePage?.page?.theme);

    const onSaveUpdates = async () => {
        setUpdating(true);
        const updatedPage = await Moralis.Cloud.run('updatePage', {
            stage: process.env.GATSBY_STAGE,
            page: {
                [PAGE_THEME]: theme,
            },
        });
        setUpdating(false);
        toast.success('Updated successfully!', {
            style: {
                background: '#333',
                color: '#fff',
            },
        });

        await refetchServerPage(updatedPage.data.address);
    };

    return (
        <Tile>
            <Text textAlign="center" opacity="0.7">
                Theme / Vibe
            </Text>
            <Text
                textAlign="center"
                marginBottom="2rem"
                opacity="0.7"
                fontSize="0.8rem"
            >
                Personalize your MetaPage by picking a theme/vibe that suits
                your preference.
            </Text>
            {hasUpdates && (
                <Flex align="center" justify="center" marginBottom="2rem">
                    <G3Button
                        size="sm"
                        variant="outline"
                        isLoading={updating}
                        leftIcon={
                            <CgArrowsExchangeAlt
                                size="2rem"
                                opacity="0.5"
                                color={useColorModeValue('black', 'white')}
                            />
                        }
                        onClick={onSaveUpdates}
                        marginRight="1rem"
                    >
                        Update
                    </G3Button>
                </Flex>
            )}
            <Flex direction="column" align="center">
                <Flex align="center " justify="center" gap="1rem" wrap="wrap">
                    {BACKGROUNDS?.map((background: BackgroundColoursTypes) => (
                        <Flex
                            cursor="pointer"
                            border={
                                background ===
                                (theme?.backgroundTheme || 'V1_PURPLE')
                                    ? 'solid 1px white'
                                    : 0
                            }
                            width="2.5rem"
                            height="2.5rem"
                            backgroundColor={
                                GLOW_BACKGROUNDS?.[background]?.colour
                            }
                            onClick={() =>
                                setTheme({
                                    ...theme,
                                    backgroundTheme: background,
                                })
                            }
                            opacity="0.8"
                            borderRadius="0.375rem"
                        ></Flex>
                    ))}
                </Flex>
            </Flex>
        </Tile>
    );
};
