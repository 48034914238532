import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import { Box, Flex, Text } from '@chakra-ui/react';
import LivePulseIndicator from '../../../../../general/LivePulseIndicator';

export default ({ containerProps }: { containerProps?: any }) => {
    const { Moralis, isAuthenticated } = useMoralis();

    const [metaPageWebInsights, setMetaPageWebInsights] = useState(
        {} as { data: any }
    );

    const [, setIsLoading] = useState(false);

    const fetchLiveData = async () => {
        setIsLoading(true);

        const response = await Moralis.Cloud.run('getMetaPageVisitsInsights', {
            stage: process.env.GATSBY_STAGE,
            live: true,
        });

        setIsLoading(false);
        setMetaPageWebInsights(response?.data);
    };

    useEffect(() => {
        if (isAuthenticated) {
            const POLL_TIME = 10000;
            fetchLiveData();
            const interval = setInterval(() => fetchLiveData(), POLL_TIME);

            return () => {
                clearInterval(interval);
            };
        }
    }, [isAuthenticated]);

    return (
        <Flex direction="column">
            <Flex align="center" gap="1rem" {...containerProps}>
                <Box>
                    <Flex
                        align={'center'}
                        justify={['center', 'space-between']}
                    >
                        <Box position="relative" h="50px" w="50px">
                            <LivePulseIndicator />
                        </Box>
                        <Text fontSize="3rem">
                            {metaPageWebInsights?.data?.uniqueVisits}
                        </Text>
                    </Flex>
                    <Text textAlign={['center', 'right']} opacity={0.5}>Live Visitors</Text>
                </Box>
            </Flex>
        </Flex>
    );
};
