import { uniqBy } from 'lodash';
import React, { createContext, useEffect, useState } from 'react';
import getTokenPK from '../../../../utils/getTokenPK';
import {
    GenverseTokenType,
    ManageMoralisUserTokensContextType,
} from '../../../../../../types';
import useGenversePage from '../../../../hooks/useGenversePage';
import { useMoralis } from 'react-moralis';
import useRefetchPageFromServer from '../../../../hooks/useRefetchPageFromServer';
import toast from 'react-hot-toast';

export const ManageMoralisUserTokensContext = createContext({});

const ManageMoralisUserTokensProvider = ({
    singleSelect = false,
    children,
}: {
    singleSelect?: boolean;
    children: React.ReactNode;
}) => {
    const { Moralis, user, isInitialized } = useMoralis();
    const ethAddress = user?.get('ethAddress');
    const { refetchServerPage } = useRefetchPageFromServer();

    /*
     *  PUBLIC tokens (Tokens visible to other users)
     * */
    const {
        genversePage,
        hasNextPage: hasNextPublicTokens,
        loadNextPage: fetchNextPublicTokens,
        loading: loadingPublicTokens,
    } = useGenversePage({ address: ethAddress, groupBy: 'BY_NFT' });

    const [publicTokens, setPublicTokens] = useState(
        genversePage?.tokens || []
    );

    useEffect(() => {
        if (!loadingPublicTokens && !!genversePage) {
            setPublicTokens(genversePage?.tokens as GenverseTokenType[]);
        }
    }, [genversePage, loadingPublicTokens]);

    /*
     *  ALL tokens (All Tokens irrespective of show/hidden status)
     * */

    const [allTokens, setAllTokens] = useState([] as GenverseTokenType[]);
    const [allTokensCursor, setAllTokensCursor] = useState();

    const [loadingAllTokens, setLoadingAllTokens] = useState(false);

    const fetchNextAllTokens = async (fromStart?: boolean) => {
        setLoadingAllTokens(true);

        const response = await Moralis.Cloud.run('getAllTokens', {
            stage: process.env.GATSBY_STAGE,
            cursor: fromStart ? null : allTokensCursor,
        });

        if (response?.data?.tokens?.length > 0) {
            setAllTokens([
                ...(fromStart ? [] : allTokens),
                ...response?.data?.tokens,
            ]);
            setAllTokensCursor(response?.data?.cursor);
        }

        setLoadingAllTokens(false);
    };

    /*
     *  HIDDEN tokens (Tokens hidden from other users)
     * */

    const [hiddenTokens, setHiddenTokens] = useState([] as GenverseTokenType[]);
    const [hiddenTokensCursor, setHiddenTokensCursor] = useState();

    const [loadingHiddenTokens, setLoadingHiddenTokens] = useState(false);

    const fetchNextHiddenTokens = async (fromStart?: boolean) => {
        setLoadingHiddenTokens(true);
        const response = await Moralis.Cloud.run('getHiddenTokens', {
            stage: process.env.GATSBY_STAGE,
            cursor: fromStart ? null : hiddenTokensCursor,
        });

        if (response?.data?.tokens?.length > 0) {
            setHiddenTokens([
                ...(fromStart ? [] : hiddenTokens),
                ...response?.data?.tokens,
            ]);
            setHiddenTokensCursor(response?.data?.cursor);
        }

        setLoadingHiddenTokens(false);
    };

    useEffect(() => {
        if (isInitialized) {
            fetchNextAllTokens();
            fetchNextHiddenTokens();
        }
    }, [isInitialized]);

    /*
     * Selected tokens management
     * */
    const [selectedTokens, setSelectedTokens] = useState(
        [] as GenverseTokenType[]
    );

    const selectToken = (
        token: GenverseTokenType,
        config: { remove?: false }
    ) => {
        if (config?.remove) {
            if (singleSelect) {
                setSelectedTokens([]);
            } else {
                setSelectedTokens(
                    selectedTokens?.filter(
                        (selectedToken) =>
                            getTokenPK(selectedToken) !== getTokenPK(token)
                    )
                );
            }
        } else {
            if (singleSelect) {
                setSelectedTokens([token]);
            } else {
                setSelectedTokens(
                    uniqBy([...selectedTokens, token], getTokenPK)
                );
            }
        }
    };

    const clearSelectedTokens = () => setSelectedTokens([]);

    const [updatingSelectedTokensSettings, setUpdatingSelectedTokensSettings] =
        useState(false as false | 'SHOW' | 'HIDE');

    const updateSelectedTokensSettings = async (action: 'SHOW' | 'HIDE') => {
        if (!selectedTokens?.length) {
            return toast.error(
                'Select one or more tokens to perform this action!',
                {
                    style: {
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        }

        setUpdatingSelectedTokensSettings(action);
        await Moralis.Cloud.run('updateTokensSettings', {
            stage: process.env.GATSBY_STAGE,
            tokensSettings: selectedTokens?.map((token) => ({
                PK: getTokenPK(token),
                displaySetting: action,
            })),
        });

        await refetchServerPage(ethAddress);
        await fetchNextAllTokens(true);
        await fetchNextHiddenTokens(true);
        setUpdatingSelectedTokensSettings(false);
        setSelectedTokens([]);
    };

    return (
        <ManageMoralisUserTokensContext.Provider
            value={
                {
                    selectToken,
                    clearSelectedTokens,
                    selectedTokens,

                    updateSelectedTokensSettings,
                    updatingSelectedTokensSettings,

                    allTokens,
                    loadingAllTokens,
                    hasNextAllTokens: !!allTokensCursor,
                    fetchNextAllTokens,

                    publicTokens,
                    loadingPublicTokens,
                    hasNextPublicTokens,
                    fetchNextPublicTokens,

                    hiddenTokens,
                    loadingHiddenTokens,
                    hasNextHiddenTokens: !!hiddenTokensCursor,
                    fetchNextHiddenTokens,
                } as ManageMoralisUserTokensContextType
            }
        >
            {children}
        </ManageMoralisUserTokensContext.Provider>
    );
};

export default ManageMoralisUserTokensProvider;
