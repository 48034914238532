import React, { useEffect, useRef } from 'react';
import { TweenMax } from 'gsap';
import './style.scss';
import { Box } from '@chakra-ui/react';

export default () => {
    const outerRef = useRef(null);
    const middleRef = useRef(null);

    useEffect(() => {
        if (outerRef?.current && middleRef?.current) {
            TweenMax.staggerTo([outerRef.current, middleRef.current], 1.5, {
                opacity: 0,
                scale: 1.5,
                repeat: -1,
            });
        }
    }, [outerRef, middleRef]);

    return (
        <Box className="live-pulse-indicator-wrapper">
            <Box className="live-pulse-indicator-wrapper__inner" />
            <Box
                className="live-pulse-indicator-wrapper__middle"
                ref={middleRef}
            />
            <Box
                className="live-pulse-indicator-wrapper__outer"
                ref={outerRef}
            />
        </Box>
    );
};
