import React, { useEffect, useState } from 'react';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {Box, Flex, Select, Text, useColorModeValue} from '@chakra-ui/react';
import { useMoralis } from 'react-moralis';
import { startCase } from 'lodash';
import moment from 'moment';
import G3LogoSpinner from '../../../../../../../general/G3LogoSpinner';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default () => {
    const { Moralis, isAuthenticated } = useMoralis();

    const [metaPageWebInsights, setMetaPageWebInsights] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [periodOptions] = useState([
        {
            label: 'Minutes',
            value: 'minute',
        },
        {
            label: 'Hourly',
            value: 'hour',
        },
        {
            label: 'Daily',
            value: 'day',
        },
        {
            label: 'Weekly',
            value: 'week',
        },
        {
            label: 'Monthly',
            value: 'month',
        },
        {
            label: 'Quarterly',
            value: 'quarter',
        },
        {
            label: 'Yearly',
            value: 'year',
        },
    ]);

    // @ts-ignore
    const [interval, setInterval] = useState('day');

    useEffect(() => {
        if (isAuthenticated && interval) {
            (async () => {
                setIsLoading(true);
                const data = await Moralis.Cloud.run(
                    'getMetaPageVisitsInsights',
                    {
                        stage: process.env.GATSBY_STAGE,
                        interval,
                    }
                );
                setIsLoading(false);
                setMetaPageWebInsights(data?.data);
            })();
        }
    }, [isAuthenticated, interval]);

    const data = {
        labels: metaPageWebInsights?.map(({ date }) =>
            moment(date).local().format(
                {
                    minute: 'HH:mm',
                    hour: 'HH:mm',
                    day: 'll',
                    week: 'll',
                    month: 'MMM YYYY',
                    quarter: 'MMM YYYY',
                    year: 'YYYY',
                }[interval]
            )
        ),
        datasets: [
            {
                label: `Visits / ${startCase(interval)}`,
                data: metaPageWebInsights?.map(
                    ({ totalVisits }) => totalVisits
                ),
                backgroundColor: useColorModeValue(
                    'rgba(49,41,138,0.35)',
                    'rgba(255, 255, 255, 0.35)'
                ),
                borderColor: useColorModeValue(
                    'rgba(49,41,138,0.7)',
                    'rgba(255, 255, 255, 0.7)'
                ),
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                //     position: 'top' as const,
                display: false,
            },
            title: {
                display: false,
                text: `Visits / ${startCase(interval)}`,
            },
        },
        scales: {
            x: {
                grid: {
                    color: useColorModeValue(
                        'rgba(0, 0, 0, 0.1)',
                        'rgba(255, 255, 255, 0.1)'
                    ),
                },
                ticks: {
                    color: useColorModeValue('black', 'white'),
                },
            },
            y: {
                grid: {
                    color: useColorModeValue(
                        'rgba(0, 0, 0, 0.1)',
                        'rgba(255, 255, 255, 0.1)'
                    ),
                },
                ticks: {
                    color: useColorModeValue('black', 'white'),
                    precision: 0,
                },
            },
        },
    };

    return (
        <Flex direction="column" align="center" width={['80vw', '30vw']}>
            <Flex
                justify="center"
                width="100%"
                align="center"
                marginBottom="1rem"
            >
                <Text fontSize="1rem" marginRight=".5rem">{`Visits |`}</Text>
                <Box maxWidth={['40%', '10rem']}>
                    <Select
                        id="grid-item-display-count-select"
                        variant="outline"
                        size="sm"
                        value={interval}
                        onChange={(e) => setInterval(e.target.value)}
                        borderColor={useColorModeValue(
                            'rgba(0, 0, 0, 0.1)',
                            'rgba(255, 255, 255, 0.1)'
                        )}
                    >
                        {periodOptions.map(({ label, value }) => (
                            <option value={value}>{label}</option>
                        ))}
                    </Select>
                </Box>
            </Flex>
            {isLoading ? (
                <G3LogoSpinner
                    containerProps={{
                        h: '20vh',
                    }}
                />
            ) : (
                <Bar options={chartOptions} data={data} />
            )}
        </Flex>
    );
};
