import { Button, Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

export default ({
    title,
    subtitle,
    subtitleAction,
    children,
}: {
    title: string;
    subtitle?: string;
    subtitleAction?: () => void;
    children?: React.ReactNode;
}) => (
    <Flex
        padding={['0 1rem', 0]}
        marginBottom={['2rem', 0]}
        direction={['column', 'row']}
        align="center"
        justify={['center', 'space-between']}
    >
        <Box>
            <Text fontSize="2rem" textAlign={['center', 'left']}>
                {title}
            </Text>
            {!!subtitle &&
                (subtitleAction ? (
                    <Button
                        size="sm"
                        fontSize="1rem"
                        marginTop=".5rem"
                        variant="ghost"
                        onClick={subtitleAction}
                        padding={0}
                        _hover={{
                            background: 'transparent !important',
                        }}
                    >
                        {`${subtitle} →`}
                    </Button>
                ) : (
                    <Text
                        opacity="0.5"
                        fontSize="1rem"
                        textAlign={['center', 'left']}
                    >
                        {subtitle}
                    </Text>
                ))}
        </Box>
        <Flex
            justify={['center', 'flex-end']}
            align="center"
            marginTop={['1rem', 0]}
        >
            {children && children}
        </Flex>
    </Flex>
);
