import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import useGenversePage from '../../../../../../hooks/useGenversePage';
import Tile from '../../../../../base/Tile';
import { useMoralis } from 'react-moralis';
import useRefetchPageFromServer from '../../../../../../hooks/useRefetchPageFromServer';
import toast from 'react-hot-toast';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { PAGE_BIO } from '../../../../../../consts/METAPAGE_FIELDS';
import G3Button from '../../../../../general/G3Button';

export default ({ address }: { address: string }) => {
    const { Moralis } = useMoralis();
    const { genversePage, loading } = useGenversePage({ address });
    const { refetchServerPage } = useRefetchPageFromServer();

    const [pageBioText, setPageBioText] = useState(genversePage?.page?.bio);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        if (!loading) {
            setPageBioText(genversePage?.page?.bio);
        }
    }, [genversePage, loading]);

    // const onBlurBioInput = () => updateBio(pageBioText);
    const onBioInputChange = useCallback((e) => {
        setPageBioText(e.target.value);
    }, []);

    const onUpdateBio = async () => {
        setUpdating(true);
        const updatedPage = await Moralis.Cloud.run('updatePage', {
            stage: process.env.GATSBY_STAGE,
            page: {
                [PAGE_BIO]: pageBioText,
            },
        });
        setUpdating(false);
        toast.success('Updated successfully!', {
            style: {
                background: '#333',
                color: '#fff',
            },
        });
        await refetchServerPage(updatedPage.data.address);
    };

    return (
        <Tile>
            <Text textAlign="center" opacity="0.7">
                Bio / Description
            </Text>
            <Text
                textAlign="center"
                marginBottom="2rem"
                opacity="0.7"
                fontSize="0.8rem"
            >
                Write a bio or description for your MetaPage.
            </Text>
            <Flex direction="column" align="center">
                <Box
                    background={useColorModeValue(
                        'transparent',
                        'rgba(218, 175, 255, 0.05)'
                    )}
                    backdropFilter={useColorModeValue(
                        'brightness(100%)',
                        'brightness(200%)'
                    )}
                    padding="1rem 2rem"
                    borderRadius="0.375rem"
                    width="100%"
                >
                    <Textarea
                        background="transparent"
                        border={0}
                        rows={3}
                        width="100%"
                        style={{ fontSize: '1.2rem' }}
                        whiteSpace="break-spaces"
                        value={pageBioText}
                        onChange={(e) => onBioInputChange(e)}
                        // onBlur={onBlurBioInput}
                    />
                </Box>
                {genversePage?.page?.bio !== pageBioText && (
                    <Flex
                        justify="flex-end"
                        align="center"
                        width="100%"
                        marginTop="1rem"
                    >
                        <G3Button
                            isLoading={updating}
                            disabled={genversePage?.page?.bio === pageBioText}
                            size="sm"
                            variant="outline"
                            marginTop="1rem"
                            leftIcon={
                                <CgArrowsExchangeAlt
                                    size="2rem"
                                    opacity="0.5"
                                    color={useColorModeValue('black', 'white')}
                                />
                            }
                            onClick={onUpdateBio}
                        >
                            Update
                        </G3Button>
                    </Flex>
                )}
            </Flex>
        </Tile>
    );
};
