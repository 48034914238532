import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import PageHeader from '../../components/PageHeader';
import useENSName from '../../../../hooks/useENSName';
import { useMoralis } from 'react-moralis';
import PFPTile from './components/PFPTile';
import BioTile from './components/BioTile';
import LinksTile from './components/LinksTile';
import useGenversePage from '../../../../hooks/useGenversePage';
import ThemeTile from './components/ThemeTile';
import G3LogoSpinner from '../../../general/G3LogoSpinner';
import TokensDisplaySettingTile from './components/TokensDisplaySettingTile';
import ManageMoralisUserTokensProvider from '../../../general/G3TokensScrollList/contexts/ManageMoralisUserTokensContext';

export default () => {
    const { user } = useMoralis();

    const ethAddress = user?.get('ethAddress');
    const { ensName, address } = useENSName(ethAddress);

    const { loading } = useGenversePage({ address: ethAddress });

    return (
        <Box>
            <PageHeader title="MetaPage" subtitle={ensName || address} />
            <Flex
                marginTop="1rem"
                gap="1rem"
                direction={['column', 'row']}
                padding={['0 1rem', 0]}
            >
                {loading && (
                    <G3LogoSpinner
                        containerProps={{
                            h: '20vh',
                        }}
                    />
                )}
                {!loading && (
                    <>
                        <Flex flex={0.5} direction="column" gap="1rem">
                            <ManageMoralisUserTokensProvider singleSelect>
                                <PFPTile address={ethAddress} />
                            </ManageMoralisUserTokensProvider>
                            <LinksTile address={address} />
                        </Flex>
                        <Flex flex={0.5} direction="column" gap="1rem">
                            <BioTile address={ethAddress} />
                            <ThemeTile address={ethAddress} />
                            <TokensDisplaySettingTile address={ethAddress} />
                        </Flex>
                    </>
                )}
            </Flex>
        </Box>
    );
};
