import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

export default () => {
    return (
        <Flex>
            <Text>my dao</Text>
        </Flex>
    );
};
