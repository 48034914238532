import React from 'react';
import useNativeBalances from '../../../../../../hooks/useNativeBalances';
import Tile from '../../../../../base/Tile';
import {useMoralis} from 'react-moralis';
import {Box, Flex, Image, Text, useColorModeValue} from '@chakra-ui/react';
import formatChain from '../../../../../../utils/formatChain';
import ChainImage from '../../../../../general/ChainImage';
import G3LogoSpinner from '../../../../../general/G3LogoSpinner';

export default ({ containerProps }: { containerProps?: any }) => {
    const { Moralis } = useMoralis();
    const { balances, isLoading } = useNativeBalances(['eth', 'matic']);

    const erc20BalancesFormatted = balances?.erc20?.map(
        ({
            chain,
            balance,
            symbol,
            name,
            logo,
            thumbnail,
            token_address,
        }: {
            chain: string;
            balance: string;
            symbol: string;
            name: string;
            logo: string;
            thumbnail: string;
            token_address: string;
        }) => {
            const balanceAbsolute = Moralis.Units.FromWei(balance, 18);
            return {
                balance: balanceAbsolute,
                balanceRounded: formatChain(
                    // @ts-ignore
                    parseFloat(balanceAbsolute)?.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4,
                    }),
                    symbol
                ),
                chain: chain?.replace('matic', 'polygon'),
                symbol,
                name,
                logo,
                thumbnail,
                token_address,
            };
        }
    );

    return (
        <Flex direction="column">
            <Text fontSize="1rem" opacity="0.5" marginBottom="1rem">
                ERC20 Tokens
            </Text>
            <Flex direction={['column', 'row']} gap="1rem" {...containerProps}>
                <Tile flex={1} direction="column" padding={0}>
                    {isLoading && (
                        <G3LogoSpinner
                            containerProps={{
                                h: '20vh',
                            }}
                        />
                    )}
                    {!isLoading && (
                        <>
                            <Box
                                padding={['0.5rem 1rem', '0.5rem 2rem']}
                                background={'rgba(255, 255, 255, 0.1)'}
                            >
                                <Text
                                    opacity="0.5"
                                    fontSize=".8rem"
                                    textAlign="center"
                                >
                                    ERC20 Tokens
                                </Text>
                            </Box>
                            <Flex
                                direction="column"
                                gap=".5rem"
                                height={['25vh', '40vh']}
                                overflow="scroll"
                            >
                                {erc20BalancesFormatted?.map(
                                    (
                                        {
                                            name,
                                            balanceRounded,
                                            chain,
                                            logo,
                                            thumbnail,
                                        },
                                        index
                                    ) => (
                                        <Flex
                                            align="center"
                                            background={
                                                index % 2 === 0
                                                    ? useColorModeValue(
                                                        'rgba(0, 0, 0, 0.1)',
                                                        'rgba(0, 0, 0, 0.3)'
                                                    )
                                                    : 'transparent'
                                            }
                                            padding="0.5rem"
                                        >
                                            <Box
                                                height="1.5rem"
                                                width="1.5rem"
                                                marginRight="1rem"
                                            >
                                                {!!(logo || thumbnail) && (
                                                    <Image
                                                        src={logo || thumbnail}
                                                        height="100%"
                                                        width="100%"
                                                    />
                                                )}
                                            </Box>
                                            <Flex direction="column" flex={1}>
                                                <Text fontSize=".8rem">
                                                    {balanceRounded}
                                                </Text>
                                                <Text
                                                    fontSize=".8rem"
                                                    opacity=".5"
                                                >
                                                    {name}
                                                </Text>
                                            </Flex>
                                            <ChainImage
                                                containerProps={{
                                                    marginLeft: 0,
                                                    height: '1.5rem',
                                                    width: '1.5rem',
                                                }}
                                                chain={
                                                    chain as 'eth' | 'polygon'
                                                }
                                            />
                                        </Flex>
                                    )
                                )}
                            </Flex>
                        </>
                    )}
                </Tile>
            </Flex>
        </Flex>
    );
};
