import React, { useContext } from 'react';
import {
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useColorModeValue,
} from '@chakra-ui/react';
import './style.css';
import {
    GenverseTokenType,
    ManageMoralisUserTokensContextType,
} from '../../../../../types';
import G3AllTokensScrollList from '../G3TokensScrollList/AllTokensList';
import { ManageMoralisUserTokensContext } from '../G3TokensScrollList/contexts/ManageMoralisUserTokensContext';
import getTokenPK from '../../../utils/getTokenPK';
import useGenversePage from '../../../hooks/useGenversePage';
import { useMoralis } from 'react-moralis';

export default ({
    showModal,
    walletAddress,
    title,
    subtitle,
    onSelectToken,
    onClose,
}: {
    showModal: null | boolean;
    walletAddress: string;
    title: string;
    subtitle?: string;
    onSelectToken: (token: GenverseTokenType) => void;
    onClose: () => void;
}) => {
    const { user } = useMoralis();

    const ethAddress = user?.get('ethAddress');
    const { genversePage } = useGenversePage({ address: ethAddress });

    const { selectedTokens } = useContext(
        ManageMoralisUserTokensContext
    ) as ManageMoralisUserTokensContextType;

    const onClickUpdate = async () => {
        if (!selectedTokens?.length) return null;
        onClose();
        await onSelectToken(selectedTokens[0]);
    };

    return (
        <Modal
            isOpen={!!showModal}
            isCentered
            scrollBehavior="inside"
            // closeIcon={<CloseIcon w={4} h={4} color="primary" />}
            onClose={onClose}
        >
            <ModalOverlay backgroundColor={'rgba(0, 0, 0, 0.9);'} />
            <ModalContent
                background={useColorModeValue('white', 'black')}
                border="solid 1px rgba(255, 255, 255, 0.15)"
                overflowY="scroll"
                w={['90vw', '30vw']}
                maxW="auto"
            >
                <ModalHeader>
                    <Heading
                        size="md"
                        textAlign={['center', 'left']}
                        marginBottom=".5rem"
                    >
                        {title || 'Select an NFT'}
                    </Heading>
                    {subtitle && (
                        <Heading
                            size="sm"
                            textAlign={['center', 'left']}
                            fontWeight="300"
                            marginBottom="1rem"
                        >
                            {subtitle}
                        </Heading>
                    )}
                </ModalHeader>
                <ModalBody padding="0 1rem">
                    <G3AllTokensScrollList
                        containerProps={{
                            id: 'scrollContainer',
                            minHeight: ['50vh', '70vh'],
                            height: ['50vh', '70vh'],
                            overflow: 'scroll',
                            marginTop: '1rem',
                        }}
                    />
                </ModalBody>

                <ModalFooter>
                    {selectedTokens?.length > 0 &&
                        getTokenPK(genversePage?.page?.primaryToken) !==
                            getTokenPK(selectedTokens[0]) && (
                            <Button
                                color="white"
                                marginRight="1rem"
                                size="md"
                                background="primary"
                                onClick={onClickUpdate}
                            >
                                Update
                            </Button>
                        )}

                    <Button size="md" background="secondary" onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
