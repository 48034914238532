import React from 'react';
import {
    Box,
    Flex,
    Text,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import { Link } from 'gatsby';
import {
    AiOutlineHome,
    AiOutlineLock,
    AiOutlineShoppingCart,
} from 'react-icons/ai';
import { MdOutlineRequestPage, MdPermMedia } from 'react-icons/md';
import { RiCoinLine, RiGovernmentLine } from 'react-icons/ri';
import { BiCustomize, BiPackage, BiPlanet } from 'react-icons/bi';
import { BsCollection } from 'react-icons/bs';
import { FaParachuteBox, FaShippingFast } from 'react-icons/fa';
import { GiOpenTreasureChest, GiTwoCoins } from 'react-icons/gi';
import { HiOutlineClipboardList, HiOutlineLightBulb } from 'react-icons/hi';
import { useLocation } from '@reach/router';
import { IoHammer, IoIosPeople, IoShirtOutline } from 'react-icons/all';

const SideBarItem = ({
    name,
    description,
    to,
    Icon,
    isActive,
    locked,
    subMenuItems,
}: {
    name: string;
    description: string;
    to?: string;
    Icon: any;
    isActive?: boolean;
    locked?: boolean;
    subMenuItems?: {
        name: string;
        to: string;
        Icon: any;
        isActive?: boolean;
        locked?: boolean;
    }[];
}) => {
    const OptionalLinkContainer = ({
        children,
        to,
        isLocked,
    }: {
        children: React.ReactNode;
        to?: string | false;
        isLocked?: boolean;
    }) =>
        isLocked ? (
            <Box>{children}</Box>
        ) : (
            <Link to={to || ''}>{children}</Link>
        );

    const background = useColorModeValue(
        'transparent',
        'rgba(255, 255, 255, 0.01)'
    );
    const border = useColorModeValue(
        'solid 1px rgba(0, 0, 0, 0.2)',
        'solid 1px rgba(255, 255, 255, 0.1)'
    );

    const activeBg = useColorModeValue('black', 'white');

    const isAnyActive =
        isActive ||
        !!subMenuItems?.filter((subMenuItem) => subMenuItem?.isActive)?.length;

    return (
        <Flex
            background={background}
            opacity={locked ? 0.7 : 1}
            marginBottom="1rem"
            borderRadius="0.375rem"
            border={border}
            overflow="hidden"
        >
            {isAnyActive && <Box width="0.5rem" backgroundColor={activeBg} />}
            <Box flex={1}>
                <OptionalLinkContainer isLocked={locked} to={!locked && to}>
                    <Box>
                        <Flex padding="1rem" align="center">
                            <Icon
                                size="1.5rem"
                                opacity={isAnyActive ? 1 : 0.7}
                                color={useColorModeValue('black', 'white')}
                            />
                            <Flex align="center" flex={1} marginLeft="1rem">
                                <Box flex={1}>
                                    <Text
                                        fontSize="1rem"
                                        fontWeight="800"
                                        opacity={isAnyActive ? 1 : 0.7}
                                    >
                                        {name}
                                    </Text>
                                    <Text
                                        fontSize=".8rem"
                                        opacity={useColorModeValue(
                                            '0.8',
                                            '0.5'
                                        )}
                                    >
                                        {description}
                                    </Text>
                                </Box>
                                {locked && (
                                    <Box marginLeft="1rem">
                                        <AiOutlineLock size="1rem" />
                                    </Box>
                                )}
                            </Flex>
                        </Flex>
                        {subMenuItems && subMenuItems?.length > 0 && (
                            <Box marginBottom="1rem">
                                {subMenuItems?.map((subMenu) => (
                                    <OptionalLinkContainer
                                        isLocked={locked || subMenu?.locked}
                                        to={subMenu?.to}
                                    >
                                        <Flex
                                            align="center"
                                            padding=".1rem .5rem"
                                            margin="0 1rem .5rem 3.5rem"
                                            borderRadius={'0.375rem'}
                                            _hover={{
                                                background:
                                                    'rgba(49, 41, 138, 0.5) !important',
                                                color: 'rgba(255, 255, 255, 1) !important',
                                            }}
                                            {...(subMenu?.isActive
                                                ? {
                                                      background:
                                                          'rgba(49, 41, 138, 0.5) !important',
                                                      color: 'rgba(255, 255, 255, 1) !important',
                                                  }
                                                : {})}
                                        >
                                            <subMenu.Icon
                                                size="1rem"
                                                color={useColorModeValue(
                                                    'black',
                                                    'white'
                                                )}
                                                opacity={
                                                    subMenu?.isActive ? 1 : 0.7
                                                }
                                            />
                                            <Text
                                                flex={1}
                                                marginLeft="1rem"
                                                fontSize="0.8rem"
                                                // fontWeight="800"
                                                // opacity={isActive ? 1 : 0.7}
                                            >
                                                {subMenu?.name}
                                            </Text>

                                            {locked || subMenu?.locked ? (
                                                <AiOutlineLock size="1rem" />
                                            ) : (
                                                <Text>→</Text>
                                            )}
                                        </Flex>
                                    </OptionalLinkContainer>
                                ))}
                            </Box>
                        )}
                    </Box>
                </OptionalLinkContainer>
            </Box>
        </Flex>
    );
};

export default () => {
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const location = useLocation();

    const isActivePath = (pathWithoutTrailingSlash: string) =>
        location.pathname.endsWith(pathWithoutTrailingSlash) ||
        location.pathname.endsWith(`${pathWithoutTrailingSlash}/`);

    return (
        <Flex
            {...(isMobile
                ? {}
                : {
                      background: useColorModeValue(
                          'rgba(0, 0, 0, 0.1)',
                          'rgba(218, 175, 255, 0.05)'
                      ),
                      backdropFilter: useColorModeValue(
                          'brightness(100%)',
                          'brightness(200%)'
                      ),
                  })}
            // border={useColorModeValue(
            //     'solid 1px rgba(0, 0, 0, .2)',
            //     'solid 1px rgba(255, 255, 255, .2)'
            // )}
            borderRadius="0.375rem"
            padding="1rem"
            direction="column"
            width="100%"
            // height={['90vh', 'unset']}
        >
            <Flex direction="column">
                <SideBarItem
                    name="Home"
                    to="/dashboard"
                    Icon={AiOutlineHome}
                    description="An overview of your Web3 business."
                    isActive={isActivePath('/dashboard')}
                />
                <SideBarItem
                    name="MetaPage"
                    Icon={MdOutlineRequestPage}
                    description="Build and customize your own Web3 website."
                    subMenuItems={[
                        {
                            name: 'Customize',
                            to: '/dashboard/metapage/customize',
                            isActive: isActivePath(
                                '/dashboard/metapage/customize'
                            ),
                            Icon: BiCustomize,
                        },
                        {
                            name: 'Collected NFTs',
                            to: '/dashboard/metapage/collected-nfts',
                            isActive: isActivePath(
                                '/dashboard/metapage/collected-nfts'
                            ),
                            Icon: BsCollection,
                        },
                    ]}
                />

                <SideBarItem
                    name="Create"
                    Icon={HiOutlineLightBulb}
                    locked
                    description="Launch your own NFT collections."
                    subMenuItems={[
                        {
                            name: 'Collections',
                            to: '/dashboard/create/collections',
                            isActive: isActivePath(
                                '/dashboard/create/collections'
                            ),
                            Icon: RiCoinLine,
                        },
                        {
                            name: 'Manage Assets',
                            to: '/dashboard/create/manage-assets',
                            isActive: isActivePath(
                                '/dashboard/create/manage-assets'
                            ),
                            Icon: MdPermMedia,
                        },
                        {
                            name: 'Finance',
                            to: '/dashboard/create/finance',
                            isActive: isActivePath('/dashboard/create/finance'),
                            Icon: GiTwoCoins,
                        },
                        {
                            name: 'Airdrops',
                            to: '/dashboard/create/airdrops',
                            isActive: isActivePath(
                                '/dashboard/create/airdrops'
                            ),
                            Icon: FaParachuteBox,
                        },
                    ]}
                />

                <SideBarItem
                    name="Community"
                    Icon={IoIosPeople}
                    locked
                    description="Organize and grow your audience."
                    subMenuItems={[
                        {
                            name: 'Genlytics',
                            to: '/dashboard/community/genlytics',
                            isActive: isActivePath(
                                '/dashboard/community/genlytics'
                            ),
                            Icon: BiPlanet,
                        },
                        {
                            name: 'Allowlist',
                            to: '/dashboard/community/allowlist',
                            isActive: isActivePath(
                                '/dashboard/community/allowlist'
                            ),
                            Icon: HiOutlineClipboardList,
                        },
                    ]}
                />

                <SideBarItem
                    name="Merchandise"
                    Icon={IoShirtOutline}
                    locked
                    description="Designs, supply, distribution & customer orders."
                    subMenuItems={[
                        {
                            name: 'Products',
                            to: '/dashboard/merch/products',
                            isActive: isActivePath('/dashboard/merch/products'),
                            Icon: BiPackage,
                        },
                        {
                            name: 'Fulfillment',
                            to: '/dashboard/merch/fulfillment',
                            isActive: isActivePath(
                                '/dashboard/merch/fulfillment'
                            ),
                            Icon: FaShippingFast,
                        },
                        {
                            name: 'Orders',
                            to: '/dashboard/merch/orders',
                            isActive: isActivePath('/dashboard/merch/orders'),
                            Icon: AiOutlineShoppingCart,
                        },
                    ]}
                />

                <SideBarItem
                    locked
                    name="DAOs"
                    Icon={RiGovernmentLine}
                    description="Own, build & share with your community"
                    subMenuItems={[
                        {
                            name: 'Governance',
                            to: '/dashboard/dao/governance',
                            isActive: isActivePath('/dashboard/merch/products'),
                            Icon: IoHammer,
                        },
                        {
                            name: 'Investors',
                            to: '/dashboard/dao/investors',
                            isActive: isActivePath('/dashboard/dao/investors'),
                            Icon: IoIosPeople,
                        },
                        {
                            name: 'Treasury',
                            to: '/dashboard/dao/treasury',
                            isActive: isActivePath('/dashboard/dao/treasury'),
                            Icon: GiOpenTreasureChest,
                        },
                        {
                            name: 'Proposals',
                            to: '/dashboard/dao/proposals',
                            isActive: isActivePath('/dashboard/dao/proposals'),
                            Icon: HiOutlineClipboardList,
                        },
                    ]}
                />
            </Flex>
        </Flex>
    );
};
