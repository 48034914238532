import React, { useState } from 'react';
import {
    AspectRatio,
    Box,
    Flex,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    GenverseTokenMetadataType,
    GenverseTokenType,
} from '../../../../../../../../types';
import NFTImage from '../../../../../general/NFTImage';
import useGenversePage from '../../../../../../hooks/useGenversePage';
import Tile from '../../../../../base/Tile';
import toast from 'react-hot-toast';
import { useMoralis } from 'react-moralis';
import useRefetchPageFromServer from '../../../../../../hooks/useRefetchPageFromServer';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { PAGE_PRIMARY_TOKEN } from '../../../../../../consts/METAPAGE_FIELDS';
import SelectWalletTokenModal from '../../../../../general/SelectWalletTokenModal';
import G3Button from '../../../../../general/G3Button';

export default ({ address }: { address: string }) => {
    const { Moralis } = useMoralis();
    const { refetchServerPage } = useRefetchPageFromServer();

    const { genversePage } = useGenversePage({ address });
    const pageToken = genversePage?.page?.primaryToken;

    const [showSelectTokenModal, setShowSelectTokenModal] = useState(false);

    const [updating, setUpdating] = useState(false);
    const onSelectToken = async (token: GenverseTokenType) => {
        setUpdating(true);
        const updatedPage = await Moralis.Cloud.run('updatePage', {
            stage: process.env.GATSBY_STAGE,
            page: {
                [PAGE_PRIMARY_TOKEN]: {
                    chain: token?.chain,
                    tokenAddress: token?.tokenAddress,
                    tokenId: token?.tokenId,
                },
            },
        });

        await refetchServerPage(updatedPage.data.address);
        toast.success('Updated successfully!', {
            style: {
                background: '#333',
                color: '#fff',
            },
        });
        setUpdating(false);
    };

    return (
        <Tile>
            <Text textAlign="center" opacity="0.7">
                Primary Token / Profile Picture (PFP)
            </Text>
            <Text
                textAlign="center"
                marginBottom="2rem"
                opacity="0.7"
                fontSize="0.8rem"
            >
                Select an NFT that you own as your primary token or profile
                picture.
            </Text>
            <Flex direction="row" justify="center" align="center">
                <AspectRatio w={['5rem', '10rem']} ratio={1}>
                    <Box
                        width="100%"
                        height="100%"
                        cursor="pointer"
                        _hover={{
                            opacity: 0.6,
                        }}
                        position="relative"
                    >
                        <NFTImage
                            borderRadius={`50%`}
                            token={
                                {
                                    metadata:
                                        pageToken?.metadata as GenverseTokenMetadataType,
                                } as GenverseTokenType
                            }
                            alt={genversePage?.ensName}
                        />
                    </Box>
                </AspectRatio>
                <Box marginLeft="1rem">
                    <Text fontSize="1rem" fontWeight="800">
                        {pageToken?.metadata?.name}
                    </Text>
                    <Text fontSize="0.8rem">
                        {pageToken?.metadata?.name || pageToken?.contract?.name}
                    </Text>
                    {!!pageToken?.tokenId && (
                        <Text fontSize="0.8rem">{`#${pageToken?.tokenId}`}</Text>
                    )}
                    <G3Button
                        isLoading={updating}
                        size="sm"
                        variant="outline"
                        marginTop="1rem"
                        leftIcon={
                            <CgArrowsExchangeAlt
                                size="2rem"
                                opacity="0.5"
                                color={useColorModeValue('black', 'white')}
                            />
                        }
                        onClick={() => setShowSelectTokenModal(true)}
                    >
                        Update
                    </G3Button>
                </Box>
            </Flex>
            <SelectWalletTokenModal
                walletAddress={address}
                title="Select your primary NFT (PFP)"
                showModal={showSelectTokenModal}
                onClose={() => setShowSelectTokenModal(false)}
                onSelectToken={onSelectToken}
            />
        </Tile>
    );
};
