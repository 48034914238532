import React, { useEffect, useState } from 'react';
import { Router, useLocation } from '@reach/router';
import Home from '../components/Dashboard/page/Home';
import MyMetaPage from '../components/Dashboard/page/MyMetaPage';
import MyNFTCollection from '../components/Dashboard/page/MyNFTCollection';
import MyDAO from '../components/Dashboard/page/MyDAO';
import { Button, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import SideBar from '../components/Dashboard/components/SideBar';
import Layout from '../components/base/Layout';
import Container from '../components/base/Container';
import { FiMenu } from 'react-icons/fi';

import G3BottomSheet from '../components/general/G3BottomSheet';
import G3TokenGateProvider from '../components/Dashboard/contexts/G3TokenGateContext';

const Dashboard = () => {
    const [showDashboardMenuSheet, setShowDashboardMenuSheet] = useState(false);
    const [isMobile] = useMediaQuery('(max-width: 768px)');

    // const { isAuthenticated, isAuthenticating } = useMoralis();

    // useEffect(() => {
    //     if (!isAuthenticating && !isAuthenticated) navigate('/');
    // }, [isAuthenticating, isAuthenticated]);

    const location = useLocation();
    useEffect(() => {
        if (showDashboardMenuSheet) setShowDashboardMenuSheet(false);
    }, [location?.pathname]);

    return (
        <Layout noContainer showNavBarLogo showSearchBar showNavLinks>
            <Flex
                margin={['2rem 0 0 0', '2rem 2rem 0 2rem']}
                direction="row"
                align="flex-start"
                justify="center"
                position="relative"
            >
                {isMobile && !showDashboardMenuSheet && (
                    <Button
                        position="fixed"
                        bottom={'5vh'}
                        left={0}
                        right={0}
                        zIndex={2000}
                        loadingText="Connecting..."
                        bg="primary"
                        color="white"
                        width="50vw"
                        borderRadius={'5rem'}
                        margin="0 auto"
                        onClick={() =>
                            setShowDashboardMenuSheet(!showDashboardMenuSheet)
                        }
                    >
                        <Flex position="relative" width="100%" height="100%">
                            <Flex
                                align="center"
                                justify="center"
                                position="absolute"
                                left={0}
                                right={0}
                                top={0}
                                bottom={0}
                            >
                                <FiMenu size="1.5rem" opacity="0.5" />

                                <Text
                                    textAlign="center"
                                    marginLeft="0.5rem"
                                >{`Dashboard`}</Text>
                            </Flex>
                        </Flex>
                    </Button>
                )}
                {isMobile ? (
                    <G3BottomSheet
                        showSheet={showDashboardMenuSheet}
                        onDismissCallback={() =>
                            setShowDashboardMenuSheet(false)
                        }
                    >
                        <SideBar />
                    </G3BottomSheet>
                ) : (
                    <Flex
                        position="sticky"
                        top={'1rem'}
                        // flex={1}
                        width={['100%', '20rem']}
                        marginRight="1rem"
                        direction="column"
                        align="flex-end"
                        justify="flex-start"
                    >
                        <SideBar />
                    </Flex>
                )}
                <Flex justify="center" flex={1}>
                    <Container largeContainer>
                        <Router basepath="/dashboard">
                            {/*@ts-ignore*/}
                            <Home {...{ path: '/' }} />
                            {/*@ts-ignore*/}
                            <MyMetaPage {...{ path: '/metapage/customize' }} />
                            {/*@ts-ignore*/}
                            <MyNFTCollection
                                {...{ path: '/metapage/collected-nfts' }}
                            />
                            {/*@ts-ignore*/}
                            <MyDAO {...{ path: '/dao' }} />
                        </Router>
                    </Container>
                </Flex>
            </Flex>
        </Layout>
    );
};

export default () => (
    <G3TokenGateProvider>
        <Dashboard />
    </G3TokenGateProvider>
);
