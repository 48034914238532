import React from 'react';
import {Flex, Text} from '@chakra-ui/react';
import useWalletTransactions from '../../../../../../hooks/useWalletTransactions';
import ENSResolvedAddress from '../../../../../general/ENSResolvedAddress';
import {useMoralis} from 'react-moralis';
import formatChain from '../../../../../../utils/formatChain';
import ChainImage from '../../../../../general/ChainImage';
import Tile from '../../../../../base/Tile';
import moment from 'moment';
import {Link} from 'gatsby';
import InfiniteScroll from 'react-infinite-scroll-component';
import './style.css';
import G3LogoSpinner from '../../../../../general/G3LogoSpinner';

export default ({
    containerProps,
}: {
    row?: boolean;
    column?: boolean;
    containerProps?: any;
}) => {
    const { Moralis } = useMoralis();
    const { transactions, hasMore, fetchMoreTransactions } =
        useWalletTransactions(['eth', 'matic']);

    return (
        <Flex direction="column">
            <Text fontSize="1rem" opacity="0.5" marginBottom="1rem">
                Transactions
            </Text>
            <Flex gap="1rem" {...containerProps}>
                <Flex
                    id="scrollContainer"
                    marginBottom="1rem"
                    width="100%"
                    height={['80vh', '50vh']}
                    overflow="scroll"
                >
                    <InfiniteScroll
                        scrollableTarget="scrollContainer"
                        scrollThreshold={0.9}
                        dataLength={transactions?.length || 0}
                        next={fetchMoreTransactions as any}
                        hasMore={hasMore as boolean}
                        style={{
                            padding: '0 0 2rem 0',
                            width: '100%',
                        }}
                        loader={
                            <Flex
                                align="center"
                                justify="center"
                                backgroundColor="rgba(255, 255, 255, 0.01)"
                                borderRadius="0.375rem"
                                padding="1rem"
                                marginTop="1rem"
                            >
                                <G3LogoSpinner
                                    containerProps={{
                                        w: '5rem',
                                    }}
                                />
                                <Text>
                                    Loading...
                                </Text>
                            </Flex>
                        }
                    >
                        <Flex direction="column" gap="1rem" width="100%">
                            {transactions?.map((transaction) => (
                                <Link
                                    to={`${
                                        {
                                            eth: 'https://etherscan.io/tx/',
                                            matic: 'https://polygonscan.com/tx/',
                                        }[transaction.chain as 'eth' | 'matic']
                                    }${transaction.hash}`}
                                    target="_blank"
                                >
                                    <Tile>
                                        <Flex
                                            direction={['column', 'row']}
                                            align={['flex-start', 'center']}
                                            width="100%"
                                        >
                                            <Flex
                                                flex={1}
                                                marginRight="1rem"
                                                direction="row"
                                                align="center"
                                            >
                                                <ChainImage
                                                    containerProps={{
                                                        // marginRight: 0,
                                                        height: '1.5rem',
                                                        width: '1.5rem',
                                                    }}
                                                    chain={
                                                        transaction.chain?.replace(
                                                            'matic',
                                                            'polygon'
                                                        ) as 'eth' | 'polygon'
                                                    }
                                                />
                                                <Flex direction="column">
                                                    <Text>
                                                        {formatChain(
                                                            parseFloat(
                                                                `${Moralis.Units.FromWei(
                                                                    `${transaction.value}`,
                                                                    18
                                                                )}`
                                                            )?.toLocaleString(
                                                                // @ts-ignore
                                                                undefined,
                                                                {
                                                                    minimumFractionDigits: 4,
                                                                    maximumFractionDigits: 4,
                                                                }
                                                            ),
                                                            transaction.chain
                                                        )}
                                                    </Text>
                                                    <Text
                                                        opacity="0.5"
                                                        fontSize="0.8rem"
                                                    >
                                                        {moment(
                                                            transaction.block_timestamp
                                                        ).format('LLLL')}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                            <Flex
                                                direction="row"
                                                align="center"
                                                marginTop={['1rem', 0]}
                                            >
                                                <ENSResolvedAddress
                                                    shortAddress
                                                    walletAddress={
                                                        transaction?.from_address
                                                    }
                                                    styles={{
                                                        margin: 0,
                                                    }}
                                                />
                                                <Text
                                                    color="tertiary"
                                                    margin="0 1rem"
                                                >
                                                    to
                                                </Text>
                                                <ENSResolvedAddress
                                                    shortAddress
                                                    walletAddress={
                                                        transaction?.to_address
                                                    }
                                                    styles={{
                                                        margin: 0,
                                                    }}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Tile>
                                </Link>
                            ))}
                        </Flex>
                    </InfiniteScroll>
                </Flex>
            </Flex>
        </Flex>
    );
};
