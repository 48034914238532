import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import {
    Box,
    Flex,
    Select,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from '@chakra-ui/react';
import Tile from '../../../../../base/Tile';
import G3LogoSpinner from '../../../../../general/G3LogoSpinner';
import { SocialIcon } from 'react-social-icons';

export default ({ containerProps }: { containerProps?: any }) => {
    const { Moralis, isAuthenticated } = useMoralis();

    const [metaPageWebInsights, setMetaPageWebInsights] = useState(
        {} as { data: any }
    );
    const [isLoading, setIsLoading] = useState(false);

    const [periodOptions] = useState([
        {
            label: 'Now',
            value: 'minute',
        },
        {
            label: 'This Hour',
            value: 'hour',
        },
        {
            label: 'This Day',
            value: 'day',
        },
        {
            label: 'This Week',
            value: 'week',
        },
        {
            label: 'This Month',
            value: 'month',
        },
        {
            label: 'This Quarter',
            value: 'quarter',
        },
        {
            label: 'This Year',
            value: 'year',
        },
    ]);

    const [selectedPeriod, setSelectedPeriod] = useState('week');

    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                setIsLoading(true);
                const response = await Moralis.Cloud.run(
                    'getMetaPageLinksInsights',
                    {
                        stage: process.env.GATSBY_STAGE,
                        period: selectedPeriod,
                    }
                );

                setIsLoading(false);
                setMetaPageWebInsights(response?.data);
            })();
        }
    }, [isAuthenticated, selectedPeriod]);

    return (
        <Flex direction="column">
            <Text fontSize="1rem" opacity="0.5" marginBottom="1rem">
                MetaPage Links
            </Text>
            <Flex direction={['column', 'row']} gap="1rem" {...containerProps}>
                <Tile overflowX="scroll">
                    <Flex
                        justify="center"
                        width="100%"
                        align="center"
                        marginBottom="1rem"
                    >
                        <Text
                            fontSize="1rem"
                            marginRight=".5rem"
                        >{`Link Clicks |`}</Text>
                        <Box maxWidth={['40%', '10rem']}>
                            <Select
                                id="grid-item-display-count-select"
                                variant="outline"
                                size="sm"
                                value={selectedPeriod}
                                onChange={(e) =>
                                    setSelectedPeriod(e.target.value)
                                }
                                borderColor={useColorModeValue(
                                    'rgba(0, 0, 0, 0.1)',
                                    'rgba(255, 255, 255, 0.1)'
                                )}
                            >
                                {periodOptions.map(({ label, value }) => (
                                    <option value={value}>{label}</option>
                                ))}
                            </Select>
                        </Box>
                    </Flex>
                    {!isLoading ? (
                        <Box h={'25vh'} overflowY="scroll">
                            <Table
                                size="lg"
                                variant="unstyled"
                                marginTop="1rem"
                            >
                                <Thead>
                                    <Tr>
                                        {[
                                            '',
                                            '',
                                            'Total Clicks',
                                            'Unique Clicks',
                                        ]?.map((label) => (
                                            <Th
                                                color={useColorModeValue(
                                                    'rgba(0, 0, 0, 0.5)',
                                                    'rgba(255, 255, 255, 0.5)'
                                                )}
                                                fontSize=".8rem"
                                                px=".5rem"
                                                py=".5rem"
                                            >
                                                {label}
                                            </Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {metaPageWebInsights?.data
                                        ?.filter(
                                            ({ url }: { url: string }) =>
                                                url?.length > 0
                                        )
                                        ?.map(
                                            (
                                                {
                                                    label,
                                                    url,
                                                    totalVisits,
                                                    uniqueVisits,
                                                }: {
                                                    label: string;
                                                    url: string;
                                                    totalVisits: number;
                                                    uniqueVisits: number;
                                                },
                                                keyIndex: number
                                            ) => (
                                                <Tr
                                                    maxWidth="15rem"
                                                    background={
                                                        keyIndex % 2 === 0
                                                            ? useColorModeValue(
                                                                  'rgba(0, 0, 0, 0.1)',
                                                                  'rgba(0, 0, 0, 0.3)'
                                                              )
                                                            : 'transparent'
                                                    }
                                                >
                                                    {[
                                                        label,
                                                        url,
                                                        totalVisits,
                                                        uniqueVisits,
                                                    ]?.map((value, index) => (
                                                        <Td
                                                            color={useColorModeValue(
                                                                'black',
                                                                'white'
                                                            )}
                                                            fontSize=".8rem"
                                                            px=".5rem"
                                                            py=".5rem"
                                                        >
                                                            <a
                                                                href={url}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <Box
                                                                    {...(index ===
                                                                    1 // urls can get long ;(
                                                                        ? {
                                                                              width: '10rem',
                                                                              textOverflow:
                                                                                  'ellipsis',
                                                                              overflow:
                                                                                  'hidden',
                                                                              whiteSpace:
                                                                                  'nowrap',
                                                                          }
                                                                        : {})}
                                                                >
                                                                    {index ===
                                                                    0 ? (
                                                                        <Flex align="center">
                                                                            <SocialIcon
                                                                                bgColor={useColorModeValue(
                                                                                    'rgba(0, 0, 0, 0.8)',
                                                                                    'rgba(255, 255, 255, 0.8)'
                                                                                )}
                                                                                fgColor={useColorModeValue(
                                                                                    'white',
                                                                                    'black'
                                                                                )}
                                                                                url={
                                                                                    url
                                                                                }
                                                                                style={{
                                                                                    width: '1.5rem',
                                                                                    height: '1.5rem',
                                                                                    marginRight:
                                                                                        '1rem',
                                                                                }}
                                                                                target="_blank"
                                                                            />
                                                                            <Box>
                                                                                {
                                                                                    value
                                                                                }
                                                                            </Box>
                                                                        </Flex>
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </Box>
                                                            </a>
                                                        </Td>
                                                    ))}
                                                </Tr>
                                            )
                                        )}
                                </Tbody>
                            </Table>
                        </Box>
                    ) : (
                        <G3LogoSpinner
                            containerProps={{
                                h: '20vh',
                            }}
                        />
                    )}
                </Tile>
            </Flex>
        </Flex>
    );
};
